import { Component, OnInit, OnDestroy, ElementRef, ViewChild, NgZone, ChangeDetectorRef, ApplicationRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { LaunchDarklyService, PrescreenerService, SessionDataService } from 'app/shared/services';
import { Prescreener } from 'app/shared/definitions';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from 'app/../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro/dist/fp.esm.min';
import { FeatureFlags } from 'app/shared/services/launch-darkly.service';

declare const grecaptcha: any;
declare global {
    interface Window {
        grecaptcha: any;
        reCaptchaLoad: () => void;
        hcaptcha: any;
    }
}

// Renew JS Fingerprint visitor ID after 90 days
const EXPIRE_CACHED_JSFINGERPRINT_RESPONSE_AFTER = 1000 * 60 * 60 * 24 * 90;
// Don't cache value if score is less than 0.8
const MIN_CACHED_JSFINGERPRINT_SCORE = 0.8;
// Local storage key
const FPJS_ID_RESPONSE_STORAGE_KEY = 'sv';

// Cleaning up some magic strings
const HCAPTCHA_TEST_NAME = 'hcaptcha';
const RECAPTCHA_TEST_NAME = 'captcha';

@Component({
    selector: 'app-screener-test',
    templateUrl: './screener.component.html',
    styleUrls: ['./screener.component.scss']
})
export class ScreenerComponent implements OnInit, OnDestroy {


    private prescreener_uuid: string;
    private base_url: string = "";

    private screener_form: FormGroup;
    private submit_attempted: boolean = false;
    private is_form_state_invalid: boolean = false;

    private recaptcha_widget_id: number;
    private captcha_succeeded: boolean = false;
    private use_recaptcha: boolean = true;

    private hcaptcha_succeeded: boolean = false;
    private show_hcaptcha: boolean = false;
    private show_invisible_hcaptcha: boolean = false;

    private error_code: string = null;
    private visitor_id: string = null;
    private fpJsIdentifyResult: any = null;

    private navigation_subscription: Subscription;
    private fpJsLoaded: Promise<any> = null;

    private hCaptchaWidgetId: string = null;
    private hCaptchaScriptLoaded: boolean = false;

    private queryParams: any;

    private featureFlagsLoaded: Promise<any>;

    @ViewChild('recaptcha', { read: ElementRef }) recaptcha: ElementRef;

    constructor(
        private session_data: SessionDataService,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private screener_service: PrescreenerService,
        private zone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private applicationRef: ApplicationRef,
        private featureFlagging: LaunchDarklyService,
    ) { 
        // This should be a near-immediate callback but we want to make sure that we don't have a full-on race condition here
        this.featureFlagsLoaded = this.featureFlagging.loadAll()
            .then(() => {
                this.show_hcaptcha = this.featureFlagging.get(FeatureFlags.HCaptchaEnabled) || this.featureFlagging.get(FeatureFlags.AlwaysEvaluateHCaptcha);
                this.show_invisible_hcaptcha = this.featureFlagging.get(FeatureFlags.AlwaysEvaluateHCaptcha) === true && this.featureFlagging.get(FeatureFlags.HCaptchaEnabled) === false;
                this.use_recaptcha = !this.featureFlagging.get(FeatureFlags.HCaptchaEnabled);

                // Prescreener may be reloaded from testing suite (language changed, record reset, etc)
                if (this.use_recaptcha) {
                    this.injectRecaptchaScript();
                }
            });
    }

    ngOnInit() {
        // NOTE: We want to initialize fingerprintJS Pro as early as possible after we have a handle to the UI.  This is necessary because FingerprintJS does 
        // much more than just look for a device fingerprint match.  It assesses biometrics and about a zillion other things, so the more opportunity it has 
        // to capture user inputs and device manipulations before making the "IDENTIFY" call...the better!
        this.loadFingerprintJS();

        this.screener_form = new FormGroup({ "answers": new FormArray([]) });

        this.navigation_subscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.loadPrescreener();
            }
        });

        this.featureFlagsLoaded.then(() => {
            if (this.use_recaptcha) {
                window.reCaptchaLoad = () => {
                    const config = {
                        sitekey: environment.site_key,
                        callback: this.onRecaptchaSuccess.bind(this),
                        'expired-callback': this.onRecaptchaExpired.bind(this)
                    };
                    this.recaptcha_widget_id = grecaptcha.render(this.recaptcha.nativeElement, config);
                }
            }
        });

        this.insertHCaptcha();

        this.loadPrescreener();

        this.route.queryParams.subscribe(res => this.queryParams = res)
    }

    ngAfterViewChecked() {
        if (this.show_hcaptcha && this.hCaptchaScriptLoaded
            && !this.screener_service.loading && this.screener_service.Prescreener.Status == 'in_progress'
            && !this.screener_service.error_encountered && !this.hCaptchaWidgetId) {
                this.renderHCaptcha();
        }
    }

    ngOnDestroy() {
        if (this.navigation_subscription) this.navigation_subscription.unsubscribe();
    }

    injectRecaptchaScript() {
        let script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    insertHCaptcha() {
        const script = document.createElement('script');
        script.src = "https://hcaptcha.com/1/api.js?hl=en&render=explicit&recaptchacompat=off";
        script.async = true;
        script.defer = true;
        script.onload = () => {
            this.hCaptchaScriptLoaded = true;
        };
        document.head.appendChild(script);
    }

    renderHCaptcha() {
        const div = document.getElementById("hcaptcha");
        this.hCaptchaWidgetId = window.hcaptcha.render(div, {
            sitekey: environment.hCaptchaSiteKey,
            "expired-callback": this.onHCaptchaExpired,
            size: this.show_invisible_hcaptcha ? "invisible" : "normal",
        });
    }

    loadFingerprintJS() {
        if (this.fpJsLoaded !== null) {
            return;
        }

        const fpJsLoaderArgs: any = {
            token: environment.fingerprintJsToken
        };

        if (!this.screener_service.runningLocalDev) {
            fpJsLoaderArgs.endpoint = environment.fingerprintJsEndpoint;
        }

        this.fpJsLoaded = FingerprintJS.load(fpJsLoaderArgs);
    }

    loadPrescreener() {
        this.resetVariables();
        this.screener_service.initializePrescreener(this.prescreener_uuid).subscribe(prescreener => {

            this.captcha_succeeded = prescreener.testPassed(RECAPTCHA_TEST_NAME);
            this.hcaptcha_succeeded = prescreener.testPassed(HCAPTCHA_TEST_NAME);

            // We want to figure out whether or not to show the hcaptcha early (hence loading launchdarkly flags in constructor)
            this.show_hcaptcha = prescreener.hasTest(HCAPTCHA_TEST_NAME) && !this.hcaptcha_succeeded && this.show_hcaptcha;
            
            for (let question in prescreener.Questions) {
                (<FormArray>this.screener_form.controls["answers"]).push(new FormControl());
            }
            
            // Once the pre-screener is loaded then we need to re-assess whether recaptcha is needed.  It will have less to do 
            // with whether or not hCaptcha is enabled, and much more to do with whether this is an older pre-screener which still wants captcha...
            // In short, we need to be turning captcha OFF on re-render, not turning it ON (as the script is injected in ngInit)
            this.featureFlagsLoaded.then(() => {
                this.use_recaptcha = this.use_recaptcha && prescreener.hasTest(RECAPTCHA_TEST_NAME) && !this.captcha_succeeded;
            });
        }, error => this.handleError(error));
    }

    private resetVariables() {
        this.submit_attempted = false;
        this.screener_service.error_encountered = false;
        let query_params = this.route.snapshot.queryParams;
        this.prescreener_uuid = this.route.snapshot.params["screener_id"];

        // Use for Webhook Authentication
        this.session_data.panelist_uuid = this.screener_service.PanelistUuid;
        this.session_data.prescreener_uuid = this.prescreener_uuid;

        this.screener_service.AdditionalQueryParams = Object.keys(query_params)
            .filter(param => (["id", "rl", "project", "lang"].indexOf(param) == -1))
            .map(param => `&${param}=${query_params[param]}`).join("");
        this.translate.use(this.screener_service.LanguageCode);
        this.captcha_succeeded = false;
        this.hcaptcha_succeeded = false;
        for (let i: number = (<FormArray>this.screener_form.controls["answers"]).length; i > 0; i--)
            (<FormArray>this.screener_form.controls["answers"]).removeAt(i - 1);
    }

    sendAnswers() {
        this.submit_attempted = false; 
        let answers = { ...this.screener_form.value, uref: this.queryParams.uref, rl: this.queryParams.rl};

        this.screener_service.sendAnswers(answers).subscribe(data => {
            if (this.use_recaptcha) {
                this.captcha_succeeded = this.screener_service.Prescreener.testPassed(RECAPTCHA_TEST_NAME);
                if (this.captcha_succeeded && this.screener_form.contains(RECAPTCHA_TEST_NAME)) { 
                    this.screener_form.controls[RECAPTCHA_TEST_NAME].disable() 
                };
            }

            for (let i: number = (<FormArray>this.screener_form.controls["answers"]).length; i > 0; i--) {
                (<FormArray>this.screener_form.controls["answers"]).removeAt(i - 1);
            }

            for (let question in this.screener_service.Prescreener.Questions) {
                (<FormArray>this.screener_form.controls["answers"]).push(new FormControl());
            }

            this.screener_form.updateValueAndValidity();
            this.applicationRef.tick(); //force a change detection for all the nodes
        }, error => {
            this.screener_service.error_encountered = true;
            this.screener_service.loading = false;
            console.error("error", error);
            this.handleError(error);
        });
    }


    onRecaptchaSuccess(data: any) {
        if (!this.use_recaptcha) {
            return;
        }

        this.screener_form.addControl(RECAPTCHA_TEST_NAME, new FormControl(data));
        this.captcha_succeeded = true;
        this.screener_service.loading = true;
        this.changeDetectorRef.detectChanges(); //force a change detection for this node
        if(this.show_hcaptcha) {
            this.executeHCaptcha();
        } else {
            this.sendAnswers();
        } 

    }

    onRecaptchaExpired() {
        if (this.use_recaptcha && !this.screener_service.Prescreener.testPassed(RECAPTCHA_TEST_NAME)) {
            grecaptcha.reset(this.recaptcha_widget_id);
            this.captcha_succeeded = false;
        }
    }

    onHCaptchaVerified(token:string) {
        this.hcaptcha_succeeded = true;
        this.screener_service.hCaptchaResult = token;

        this.sendAnswers();
    }

    onHCaptchaExpired(response:any) {
        console.log("hcaptcha expired");
        this.session_data.triggerWebhook("errors", {
            prescreenerId: this.prescreener_uuid,
            type: "hcaptcha expired",
            error: response
        });

        if (this.show_hcaptcha && !this.show_invisible_hcaptcha && !this.screener_service.Prescreener.testPassed(HCAPTCHA_TEST_NAME)) {
            this.hcaptcha_succeeded = false;
        }
    }

    onHCaptchaError(err: any) {
        console.log("hcaptcha error", err);
        this.session_data.triggerWebhook("errors", {
            prescreenerId: this.prescreener_uuid,
            type: "hcaptcha error",
            error: err
        });

        if (this.show_invisible_hcaptcha) {
            this.sendAnswers();
        } else {
            this.hcaptcha_succeeded = false;
        }
    }

    executeHCaptcha() {
        return window.hcaptcha.execute(this.hCaptchaWidgetId, {async: true})
            .then(({response, key}) => {
                console.log("hcaptcha success: ", response);
                this.screener_service.hCaptchaResult = response;
                this.sendAnswers();
            })
            .catch(err => {
                this.onHCaptchaError(err);
                return err;
            });
    }

    onClickNext() {
        if (this.submit_attempted) {
            return;
        }

        if (!this.screener_form.valid) {
            this.is_form_state_invalid = true;
            return;
        }

        this.is_form_state_invalid = false;
        
        const handleSubmit = () => {
             if (this.use_recaptcha && !this.captcha_succeeded) {
                grecaptcha.execute(this.recaptcha_widget_id);
             }
             else {
                if (this.show_hcaptcha) {
                    return this.executeHCaptcha();
                }
                else {
                    this.sendAnswers();
                }
            }
        };

        this.submit_attempted = true;

        this.fpJsLoaded
            .then(fpjsAgent => this.getVisitorIdentificationResult(fpjsAgent))
            .then(fpjsIdentifyResp => {
                this.fpJsIdentifyResult = fpjsIdentifyResp;
                this.visitor_id = fpjsIdentifyResp.visitorId;
                this.screener_service.FingerprintJsIdentificationResult = fpjsIdentifyResp;
                return handleSubmit();
            })
            .catch(err => {
                console.error('Visitor Identification Failure: ', err);
                return handleSubmit();
            });
        
    }

    getVisitorIdentificationResult(fpjsAgent) {
        if ((!document.cookie || document.cookie.indexOf('_vid_t=') !== -1) && localStorage.getItem(FPJS_ID_RESPONSE_STORAGE_KEY)) {
            // Could always use crypto.subtle here later - keeping this secure isn't necessarily as critical as just masking it a tiny bit...
            const json = atob(localStorage.getItem(FPJS_ID_RESPONSE_STORAGE_KEY));
            try {
                const cachedIdResponse = JSON.parse(json);
                if (cachedIdResponse.confidence && cachedIdResponse.confidence.score && cachedIdResponse.confidence.score >= MIN_CACHED_JSFINGERPRINT_SCORE && ((new Date().valueOf() - new Date(cachedIdResponse.timestamp).valueOf()) < EXPIRE_CACHED_JSFINGERPRINT_RESPONSE_AFTER)) {
                    delete cachedIdResponse.timestamp;
                    return Promise.resolve(cachedIdResponse);
                }
            }
            catch(err) {
                console.error("Malformed visitor designation in storage!");
            }
        }

        return fpjsAgent.get({ linkedId: this.screener_service.PanelistUuid })
            .then(fpJsIdResult => {
                if (fpJsIdResult.confidence.score >= MIN_CACHED_JSFINGERPRINT_SCORE) {
                    const toStore = JSON.stringify({ ...fpJsIdResult, timestamp: new Date() });
                    localStorage.setItem(FPJS_ID_RESPONSE_STORAGE_KEY, btoa(toStore));
                }
                return fpJsIdResult;
            });
    }

    showForm(): boolean {
        if (this.screener_service.error_encountered) return false;
        if (this.screener_service.Prescreener == undefined) return false;
        else return this.screener_service.Prescreener.Status == "in_progress";
    }

    private handleError(error: any) {
        if (error.result.code === "QUOTA_FULL" && error.result.redirectTo) {
            window.location.href = error.result.redirectTo
        }

        this.error_code = error.result.code;
    }
}
