import { Component, Input, forwardRef } from '@angular/core';
import { RadioQuestion, Trigger } from 'app/shared/definitions';
import { PreviewBase } from 'app/shared/components/screener-questions/preview-base';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, NG_VALIDATORS, FormControl  } from '@angular/forms';


@Component({
  selector: 'qm-radio-preview',
  templateUrl: './radio-preview.component.html',
  styleUrls: ['./radio-preview.component.scss'],
  exportAs: 'preview',
  providers: [
	{
	  provide: NG_VALUE_ACCESSOR,
	  useExisting: forwardRef(() => RadioPreview),
	  multi: true,
  },
  {
	  provide: NG_VALIDATORS,
	  useExisting: forwardRef(() => RadioPreview),
	  multi: true,
	}
  ]
})
export class RadioPreview extends PreviewBase implements ControlValueAccessor, Validator {

	@Input() question: RadioQuestion;

	protected internal_response: {"id": string, "text": string} = undefined;

	constructor() {
		super();
	}

	get Response(){	return this.internal_response; }
	set Response(response: {"id": string, "text"}) {
		this.internal_response = response;
		this.propagateChange({
			"question_id": this.question.QuestionId,
			"question_type": this.question.Type,
			"response": this.internal_response
		});
	}

	// this is the initial value set to the component
	public writeValue(answer: any) {
		// if (answer !== undefined && answer !== null)
		// this.internal_response = answer["response"];
	}

   // returns null when valid else the validation object
   public validate(c: FormControl) {
	   return (this.internal_response !== undefined) ? null : {"error": "Please answer the question"};
   }

}
