import { QuestionBase } from 'app/shared/definitions';

export interface Test {
    "test_name": string,
	"label": string,
	"passed"?: boolean
}

export class Prescreener {

	private languages: Object[] = [{"code": "en", "language": "English"}];  // languages (plural)
	private questions: QuestionBase[] = [];
	private num_pages_max : number;
	private max_num_questions: number;
	private current_page: number = 1;
	private question_number_start: number = 1;
	private uuid: string;
	private status : string = "in_progress";
	private tests: Test[] = [];
	private parent_tests: Test[] = [];
	private randomize_order: boolean = false;
	private redirect_url: string;
	private header_html: string;
	private use_custom_branding: boolean = false;

	private title: string;
	private subheading: string;

	constructor(uuid){
		this.uuid = uuid;
	}

	get UUID() : string { return this.uuid; }
	set UUID(uuid: string) { this.uuid = uuid; }

	get Languages() : Object[] { return this.languages; }
	set Languages(languages: Object[]) { this.languages = languages; }

	get Questions() : QuestionBase[] { return this.questions; }
	set Questions(questions: QuestionBase[]) { this.questions = questions; }

	get CurrentPage() : number { return this.current_page; }
	set CurrentPage(page: number) { this.current_page = page; }

	get NumPagesMax() : number { return this.num_pages_max; }
	set NumPagesMax(max: number) { this.num_pages_max = max; }

	get QuestionNumberStart() : number { return this.question_number_start; }
	set QuestionNumberStart(start: number) { this.question_number_start = start; }

	get MaxNumberQuestions() : number { return this.max_num_questions; }
	set MaxNumberQuestions(max: number) { this.max_num_questions = max; }

	get Tests() : Test[] { return this.tests; }
	set Tests(tests: Test[]){ this.tests = tests; }

	get ParentTests() : Test[] { return this.parent_tests; }
	set ParentTests(parent_tests: Test[]){ this.parent_tests = parent_tests; }

	get Status() : string { return this.status; }
	set Status(status: string){ this.status = status; }

	get RandomizeOrder() : boolean { return this.randomize_order; }
	set RandomizeOrder(randomize: boolean) { this.randomize_order = randomize; }

	get RedirectUrl() : string { return this.redirect_url; }
	set RedirectUrl(url: string) { this.redirect_url = url; }

	get HeaderHtml() : string { return this.header_html; }
	set HeaderHtml(html: string) { this.header_html = html; }

	get UseCustomBranding() { return this.use_custom_branding; }
	set UseCustomBranding(use_custom_branding: boolean) { this.use_custom_branding = use_custom_branding; }

	public hasTest(test_name: string): boolean {
		for (let test of this.parent_tests) {
			if (test.test_name === test_name) return true;
		}

		return false;
	}

	public testPassed(test_name: string){
		if (this.tests == undefined) return false;
		let test = this.tests.find(test => test.test_name == test_name);
		return (test == undefined) ? false : test.passed;
	}

	public reset(){
		this.status = "in_progress";
		if (Array.isArray(this.tests)) {
			for (let test of this.tests) test.passed = false;
		} 
        else {
            this.tests = [];
        }
		this.redirect_url = null;
		this.question_number_start = 1;
		this.current_page = 1;
	}



}
