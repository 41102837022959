import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { SessionDataService } from 'app/shared/services/session-data.service';
import { QuestionBase, Fxn,
	RadioQuestion, ChecklistQuestion, PatternQuestion, GeoQuestion, NumberQuestion,
	Prescreener, Action, Answer } from 'app/shared/definitions';
import { PreviewBase } from 'app/shared/components/screener-questions/preview-base';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { isLocalDevEnvironment as localDevenvCheck } from 'environments/environmentNames';


@Injectable()
export class PrescreenerService {
    private is_local_devenv: boolean = null;
    get runningLocalDev() : boolean {
        return (this.is_local_devenv === null)
            ? (this.is_local_devenv = localDevenvCheck(environment.name))
            : this.is_local_devenv;
    }

	public panelist_id: string;
	public rl_number: number;
	public language_code: string;
    private visitor_id: string = null;
	private test_mode: boolean = false;
	private query_string: string;
	private test_prefix: string = "";
	private additional_query_params: string = "";
	private live_survey_url;
    private fingerprintjs_identify_result: any = null;
    private hcaptcha_result: string = null;

	public gdpr_accepted: boolean = false;
	public loading: boolean = true;
	public broadcast_subject: Subject<Object> = new Subject<Object>();
	public error_encountered: boolean = false;

	set GdprAccepted(accepted: boolean){
		this.gdpr_accepted = accepted;
		sessionStorage.setItem("gdpr_accepted", "true");
	}

	get TestMode() : boolean { return this.test_mode; }
	setTestMode(mode: boolean, prefix?: string) {
		this.test_mode = mode;
		this.test_prefix = (mode) ? prefix : "";
	}

	get PanelistUuid() : string { return this.panelist_id; }
	set PanelistUuid(uuid: string) { this.panelist_id = uuid; }

    get VisitorId() : string { return this.visitor_id; }
    set VisitorId(value: string) { this.visitor_id = value; }

    get FingerprintJsIdentificationResult() : any { return this.fingerprintjs_identify_result; }
    set FingerprintJsIdentificationResult(value: any) {
        this.fingerprintjs_identify_result = value;
        if (value && value.visitorId) {
            this.visitor_id = value.visitorId;
        }
    }

    get hCaptchaResult() : string {
        return this.hcaptcha_result;
    }
    set hCaptchaResult(value: string) {
        this.hcaptcha_result = value;
    }

	get RLnumber() : number { return this.rl_number; }
	set RLnumber(rl: number) { this.rl_number = rl; }

	get LanguageCode() : string { return this.language_code; }
	set LanguageCode(code: string ) {
		this.language_code = code;
		this.translate.use(code);
	}

	set AdditionalQueryParams(params: string) { this.additional_query_params = params; }

	get QueryString(){
		return `?id=${this.panelist_id}&rl=${this.rl_number}&lang=${this.language_code}${this.additional_query_params}`
	}

	get LiveSurveyUrl() : string {return this.live_survey_url; }
	set LiveSurveyUrl(url: string) { this.live_survey_url = url; }

	private prescreener: Prescreener;
	set Prescreener(prescreener) { this.prescreener = prescreener; }
	get Prescreener() { return this.prescreener; }


	private user_answers: Answer[] = [];

	constructor(private session_data: SessionDataService, private translate: TranslateService) {
		this.gdpr_accepted = sessionStorage.getItem("gdpr_accepted") == "true";
		translate.setDefaultLang('en');
		translate.use('en');
	}

	initializePrescreener(screener_id: string) : Observable<Prescreener> {
		this.user_answers = [];
		this.error_encountered = false;
		this.live_survey_url = undefined;
		this.loading = true;
		return Observable.create(observer => {
			let get_url = `${this.test_prefix}/screeners/${screener_id + this.QueryString}`;
			let prescreener_subscription = this.session_data.apiGet(get_url).subscribe(data => {
				let result = data.result;
				this.prescreener = new Prescreener(screener_id);
				if (data.result.status == "in_progress"){
					this.prescreener.NumPagesMax = result.num_pages_max;
					this.prescreener.Languages = result.languages;
					this.prescreener.QuestionNumberStart = result.question_number_start;
					this.prescreener.CurrentPage = result.page;
					this.prescreener.UseCustomBranding = result.use_custom_branding;
					this.prescreener.Tests = result.tests;
					this.prescreener.ParentTests = result.parent_tests;
					this.prescreener.HeaderHtml = result.header_html;
					this.prescreener.Questions = result.questions.map(question => this.prototypeQuestion(question["type"], question));
					if (this.test_mode){
						this.prescreener.RandomizeOrder = result.randomize_order;
						this.prescreener.MaxNumberQuestions = result.max_num_questions;
					}
				}else{
					this.prescreener.Status = data.result.status;
					this.prescreener.RedirectUrl = data.result.redirect_url;
					if (!this.test_mode) window.location.href = data.result.redirect_url;
				}
				this.loading = false;
				observer.next(this.prescreener);
				observer.complete();
			}, e => {
				this.loading = false;
				this.error_encountered = true;
				let error = JSON.parse(e._body);
				if (this.test_mode) {
					this.broadcast_subject.next(error)
				}
				observer.error(error);
			}, ()=>{
				prescreener_subscription.unsubscribe();
			});
		});
	}

	/*
	Response if Statused:
		{
			status: complete|terminate|over_quota|security_terminate
			tests: [ {label: string, passed: boolean, test_name: string, settings?: object ...} ]
			redirect_url:   test_survey_url or survey_url or partner redirect Url
		}
	*/


	public sendAnswers(answers: {"answers": Object[], "captcha"?: string, hcaptcha?: string, uref?: string }) : Observable<any>{
		this.user_answers = [];
		this.loading = true;

        const postData = {
            ...answers,
            visitor: null
        };

        // In the "invisible hCaptcha" scenario, this value will not necessarily have been added as a form field value
        // and thus will not be present in "answers"
        if (!postData.hcaptcha && this.hcaptcha_result) {
            postData.hcaptcha = this.hcaptcha_result;
        }

        if (this.fingerprintjs_identify_result) {
            const { visitorId, requestId, confidence } = this.fingerprintjs_identify_result;

            // Make sure this doesn't look like a fingerprintJS result - we want that obfuscated.
            postData.visitor = {
                id: visitorId,
                identification_id: requestId,
                confidence_score: confidence.score
            };
        }
        
		return Observable.create(observer => {
			let prescreener_subscription = this.session_data.apiPost(
				this.test_prefix + "/screeners/" + this.Prescreener.UUID + "/pages/" + this.Prescreener.CurrentPage + this.QueryString, postData
			).subscribe(data => {
				this.prescreener.Tests = data.result.tests;
				this.prescreener.Status = data.result.status;
				if (this.prescreener.Status == "in_progress"){
					this.prescreener.CurrentPage = data.result["page"];
					this.prescreener.NumPagesMax = data.result["num_pages_max"];
					this.prescreener.QuestionNumberStart = data.result["question_number_start"];
					this.prescreener.Questions = data.result["questions"].map(question =>
						this.prototypeQuestion(question["type"], question));
					this.loading = false;
					observer.complete();
				}else{
					this.prescreener.Questions = [];
					this.prescreener.RedirectUrl = data.result.redirect_url;
					if (!this.test_mode) window.location.href = data.result.redirect_url;
					this.loading = false;
					observer.next(data);
					observer.complete();
				}
			}, e => {
				this.loading = false;
				this.error_encountered = true;
				let error = JSON.parse(e._body);
				if (this.test_mode) {
					this.broadcast_subject.next(error);
				}
				observer.error(error);
			}, () => {
				prescreener_subscription.unsubscribe();
			})
		});
	}

	// // used by question previews for persistent answers
	// setAnswer(index: number, question_id: string, question_type: string, answer: any){
	// 	this.user_answers[index] = {
	// 		"question_id": question_id,
	// 		"type": question_type,
	// 		"response": answer
	// 	};
	// }

	prototypeQuestion(question_type: string, question: QuestionBase) : any{
		let typed_question;
		switch (question_type){
			case 'radio': typed_question =  new RadioQuestion(); break;
			case 'checklist': typed_question = new ChecklistQuestion(); break;
			case 'pattern': typed_question = new PatternQuestion(); break;
			case 'geographical': typed_question = new GeoQuestion(); break;
			case 'number': typed_question = new NumberQuestion(); break;
			default: {
				throw new Error("question type " + question_type + " not defined;")
			}
		}
		Object.assign(typed_question, question);
		return typed_question;
	}

}
