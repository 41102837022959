import { QuestionBase } from './base.question';

interface Option {
    "id": string,
	"text": Object,
	"groups": string[]
}

export class RadioQuestion extends QuestionBase  {

	private options: Option[] = [];

	get Options(): Option[] { return this.options; }
	set Options(opts: Option[]) { this.options = opts; }


	constructor(){
		super();
		this.type = "radio";
	}
}
