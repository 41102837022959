import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from 'app/shared/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';


import { PrescreenerService, LaunchDarklyService, SessionDataService } from 'app/shared/services';
import { TestSuiteComponent } from 'app/test/test-suite.component';
import { ScreenerComponent } from 'app/screener/screener.component';
import { UrlSerializer } from '@angular/router'
import { UrlParamNormalizer } from './url-serializer';

import { AppComponent } from './app.component';
import { HttpModule, JsonpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { ProjectGdprResolver } from 'app/shared/services/project-gdpr-resolver.service';
import { ErrorPageComponent } from './error-page/error-page.component';

import { NgHcaptchaModule } from 'ng-hcaptcha';
import { environment } from 'environments/environment';



export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

const routes: Routes = [
	{ path: 'not-found', component: ErrorPageComponent, data: {message: 'Page not found'} },
	{
		path: 'test/:password/:screener_id',
		component: TestSuiteComponent,
		resolve: {"gdpr": ProjectGdprResolver},
		children: [
			{"path": "", component: ScreenerComponent }
		]
	},
	{
		path: ':screener_id',
		resolve: {"gdpr": ProjectGdprResolver},
		component: ScreenerComponent
	},
	{ path: '**', redirectTo: '/not-found' }
];


@NgModule({
	declarations: [
		AppComponent,
		ErrorPageComponent,
		TestSuiteComponent,
		ScreenerComponent
	],
	imports: [
		BrowserModule,
		HttpModule,
		JsonpModule,
		FormsModule,
		SharedModule,
		ReactiveFormsModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),
	],
	providers: [{
		provide: UrlSerializer,
		useClass: UrlParamNormalizer
	}, SessionDataService, PrescreenerService, ProjectGdprResolver, LaunchDarklyService ],
	bootstrap: [AppComponent]
})
export class AppModule { }
