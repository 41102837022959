import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Request, Headers, RequestMethod, RequestOptions, URLSearchParams} from '@angular/http';

import { Observer, Observable, forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from 'app/../environments/environment';

interface CustomHeader {
	key: string,
	value: string
}

@Injectable()
export class SessionDataService {

	public PRODUCTION: boolean;
	public ENV_NAME: string;

	private headers: Headers;
	private custom_headers: string[]; // Store list of headers we've added so we can remove them
	private request_options : RequestOptions;

	public panelist_uuid: string;
	public prescreener_uuid: string;

	private api_url: string;

	get ApiUrl():string { return this.api_url; }
	get Router() { return this.router; }

	constructor(private http: Http, private router: Router) {
		this.PRODUCTION = environment.production;
		this.ENV_NAME = environment.name;
		this.api_url = environment.api_url;
		/* Init http variables */
		this.headers = new Headers();
		this.headers.set("Content-Type", 'application/json');
		this.headers.append("Authorization", "Basic ");
		this.custom_headers = new Array<string>();
	}

	/* Http Methods */
	private addCustomHeaders(custom_headers: Array<CustomHeader>){
		this.clearCustomHeaders()
		for(let h of custom_headers){
			this.custom_headers.push(h.key);
			this.setHeader(h.key,h.value);
		}
	}
	private setHeader(name:string,value:any){
		this.headers.set(name,value);
	}
	// If we don't clear the custom headers, then all future requests will contain any and ALL headers we add
	private clearCustomHeaders(){
		for(let h of this.custom_headers){
			this.headers.delete(h);
		}
	}

	private httpReq(url,options, custom_headers?:Array<CustomHeader>){
		if(custom_headers) this.addCustomHeaders(custom_headers);
		options.url = url;
		options.headers = this.headers;
		this.request_options = new RequestOptions(options);
		return this.http.request(new Request(this.request_options)).pipe(
							map(this.extractData),
							catchError(this.handleError.bind(this))
						);
	}
	private extractData(res: Response) {
		return res.json() || {};
	}
	private handleError (error: Response | any, webhook: boolean = true) {
		if (webhook) this.triggerWebhook("errors",error);
		throw error;
	}

	/* For Basic HTTP requests */
	public get(url, custom_headers?: Array<CustomHeader>): Observable<any> {
		let options = { method: RequestMethod.Get };
		return this.httpReq(url,options,custom_headers);
	}
	public post(url, body: Object, custom_headers?: Array<CustomHeader>): Observable<any> {
		let options = {
				method: RequestMethod.Post,
				body: JSON.stringify(body)
		};
		return this.httpReq(url, options, custom_headers);
	}

	/* For API Requests. Use ApiUrl */
	public apiGet(uri, custom_headers?: Array<CustomHeader>): Observable<any> {
		let options = { method: RequestMethod.Get };
		return this.httpReq(this.api_url + uri,options,custom_headers);
	}
	public apiDelete(uri, custom_headers?: Array<CustomHeader>): Observable<any> {
		let options = { method: RequestMethod.Delete };
		return this.httpReq(this.api_url + uri,options,custom_headers);
	}
	public apiPost(uri, body: Object, custom_headers?: Array<CustomHeader>): Observable<any> {
		let options = {
				method: RequestMethod.Post,
				body: JSON.stringify(body)
		};
		return this.httpReq(this.api_url + uri, options, custom_headers);
	}
	public apiPut(uri, body: Object, custom_headers?: Array<CustomHeader>): Observable<any> {
		let options = {
				method: RequestMethod.Put,
				body: JSON.stringify(body)
		};
		return this.httpReq(this.api_url + uri, options, custom_headers);
	}
	public apiPatch(uri, body: Object, custom_headers?: Array<CustomHeader>): Observable<any> {
		let options = {
				method: RequestMethod.Patch,
				body: JSON.stringify(body)
		};
		return this.httpReq(this.api_url + uri, options, custom_headers);
	}

	public triggerWebhook(type: string, body: Object){
		if(!this.panelist_uuid || !this.prescreener_uuid) return;
		let url = `${environment.webhook_url}/api/v1/${type}/screener?panelist_uuid=${this.panelist_uuid}&prescreener_uuid=${this.prescreener_uuid}`;
		let options = {
			url: url,
			method: RequestMethod.Post,
			headers: this.headers,
			body: body
		};
		this.request_options = new RequestOptions(options);
		return this.http.request(new Request(this.request_options)).pipe(
			map(this.extractData),
			catchError(error => of(error))
		).subscribe(data => {
			console.log("Sent error to webhook",data);
		},e => {
			console.error("Error triggering webhook",e);
		});
	}

}
