import { QuestionBase } from './base.question';
import { Reference } from './reference';

interface range {
    "type": string,
	"value"?: number,
	"from"?: number,
	"to"?: number
}

export interface NumberGroup {
    "name": string,
	"ranges": range[]
}

export class NumberQuestion extends QuestionBase  {


	private min: number;
	private max: number;
	private decimals: number;
	private groups: NumberGroup[] = [];

	get Min() : number { return this.min; }
	set Min(min: number) { this.min = min; }

	get Max() : number  { return this.max; }
	set Max(max: number) { this.max = max; }

	get Decimals() : number { return this.decimals; }
	set Decimals(decimals: number) { this.decimals = decimals; }

	get Groups() : NumberGroup[] { return this.groups; }
	set Groups(groups: NumberGroup[]) { this.groups = groups; }

	constructor(){
		super();
		this.type = "number";
	}
}
