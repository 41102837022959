import { QuestionBase } from './base.question';

export class PatternQuestion extends QuestionBase {


	constructor(){
		super();
		this.type = "pattern";
	}

	private regex_str: string;
	private element_type: string; // tel, text, or email (<input type=*>)
	private min_length: number = 1;
	public regex_path: string;
	private terminate_invalid: boolean = false;
	private groups: {"name": string, "values": string[]}[] = [];

	get ElementType() : string { return this.element_type; }
	set ElementType(type: string) { this.element_type = type; }

	get RegexStr() : string { return this.regex_str; }
	set RegexStr(regex_str: string ) { this.regex_str = regex_str; }

	get MinLength() : number { return this.min_length; }
	set MinLength(len: number) { this.min_length = len; }

	get TerminateInvalid() : boolean { return this.terminate_invalid; }
	set TerminateInvalid(term: boolean) { this.terminate_invalid = term; }

	get Groups(){ return this.groups; }
	set Groups(groups: {"name": string, "values": string[]}[]) { this.groups = groups; }

	public matches(input: string, flags='') : boolean {
		return input.match(new RegExp(this.regex_str.slice(1, -1), flags)) !== null;
	}

}
