import { environments } from "./environmentNames";
import { secrets } from "./environmentSecrets";

export const environment = {
    production: true,
    name: environments.staging,
    api_url: "https://screener-api.stg.titan.questmindshare.com/api/v1",
    manager_url: "https://qa.questtitan.com/api/v1",
    webhook_url: "https://webhook.qa.questtitan.com",
    site_key: "6Ld7kXMaAAAAAK4QFcCGhtIopObYRS2HiMoyEJYs",
    fingerprintJsEndpoint: "https://fp.invite.qa.mindsharesurveys.com",
    fingerprintJsToken: secrets.fingerprintJsToken,
    hCaptchaSiteKey: "fd75f89a-fa4a-4d33-9990-ccab1abddf06",
    launchDarklyClientSideId: '60522959d4808b0e359dce31',
};
