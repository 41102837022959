import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RadioPreview, ChecklistPreview, PatternPreview, GeoPreview, NumberPreview, AdaptableTextareaComponent } from 'app/shared/components';

// Node Modules -- application scope.  very likely to be used in all modules.
import { NguiAutoCompleteModule } from '@questmindshare/auto-complete'
import { LoadingIconComponent } from 'app/shared/components/loading-icon/loading-icon.component';

@NgModule({
	imports: [
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		NguiAutoCompleteModule,
		RouterModule
	],
	declarations: [
		LoadingIconComponent,
		AdaptableTextareaComponent,
		RadioPreview,
		ChecklistPreview,
		PatternPreview,
		GeoPreview,
		NumberPreview,
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		LoadingIconComponent,
		AdaptableTextareaComponent,
		RadioPreview,
		ChecklistPreview,
		PatternPreview,
		GeoPreview,
		NumberPreview
	],
	providers: []
})
export class SharedModule { }
