
export interface Trigger {
	"label": string, // radio, checkbox
	"state": {
		"type": string, // group or option
		"condition": string, // [X] Selected,
		"args": Object
		"id"?: string, // option_id, group_name, or other identifier
	}
}

export interface Action {
	"label": string,
	"command": string,
	"args": Object // number (show/hide)   // quota (add to quota)
}

export interface Fxn {
	"if":  {"question_id": string, "trigger_index": number, "result": boolean}[],
	"then": Action
}




export class QuestionBase {

	protected type: string;
	protected text: string;
	protected question_id: string;

	protected triggers: Trigger[] = [];
	protected show_by_default:boolean = true;

	get Text() : string { return this.text; }
	get Type() : string { return this.type; }
	get Triggers() : Trigger[] { return this.triggers; }
	get ShowByDefault() : boolean { return this.show_by_default; }
	get QuestionId() : string{ return this.question_id; }


	set Text(text: string) { this.text = text; }
	set Type(type: string) { this.type = type; }
	set Triggers(triggers: Trigger[]) { this.triggers = triggers; }
	set ShowByDefault(def: boolean) { this.show_by_default = def; }
	set QuestionId(id: string){ this.question_id  = id; }

	initFromBasics() : QuestionBase {
		let question = new QuestionBase();
		question.QuestionId = this.QuestionId;
		question.Text = this.Text;
		return question;
	}

	setRandomQuestionId() {
		this.question_id = "question_" + this.getMongoObjectId();
	}

	private getMongoObjectId() : string{
		return 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
		    return (Math.random() * 16 | 0).toString(16);
		}).toLowerCase();
	};

	constructor(){}
}
