import { QuestionBase } from './base.question';
import { Reference, GeoScope } from 'app/shared/definitions';



export class GeoQuestion extends QuestionBase {

	private input_method: string;
	private options: Reference[];
	private groups: {"name": string, "scope": string, "values": Reference[]}[] = [];
	private enable_quick_input: boolean = true;

	private scope: GeoScope;

	//   /geodata/definitions/countries/:country_iso/:scope
	get CountryIso() : string { return (this.scope) ? this.scope.route.split('/')[4] : undefined; }

	get Scope() { return this.scope; }
	set Scope(scope: GeoScope){ this.scope = scope; }

	get InputMethod() :string { return this.input_method; }
	set InputMethod(method :string) { this.input_method = method; }

	get Groups() : {"name": string, "scope": string, "values": Reference[]}[] { return this.groups; }
	set Groups(groups: {"name": string, "scope": string, "values": Reference[]}[]) { this.groups = groups; }

	get Options(){ return this.options; }
	set Options(options: Reference[]){ this.options = options; }

	get QuickInput(){ return this.enable_quick_input; }
	set QuickInput(enable_quick: boolean) { this.enable_quick_input = enable_quick; }

	constructor(){
		super();
		this.type = "geographical";
	}

}
