import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NumberQuestion, Trigger, NumberGroup } from 'app/shared/definitions';
import { PreviewBase } from 'app/shared/components/screener-questions/preview-base';
import { Observable } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, NG_VALIDATORS, FormControl  } from '@angular/forms';


@Component({
  selector: 'qm-number-preview',
  templateUrl: './number-preview.component.html',
  styleUrls: ['./number-preview.component.scss'],
  exportAs: 'preview',
  providers: [
	{
	  provide: NG_VALUE_ACCESSOR,
	  useExisting: forwardRef(() => NumberPreview),
	  multi: true,
  },
  {
	  provide: NG_VALIDATORS,
	  useExisting: forwardRef(() => NumberPreview),
	  multi: true,
	}
  ]
})
export class NumberPreview extends PreviewBase implements ControlValueAccessor, Validator, OnInit {

	@Input() question: NumberQuestion;

	protected internal_response: number = null;
	private step: number = 1;
	private width: number;

	constructor() {
		super();
	}

	setAnswer(answer: number){
		this.internal_response = answer;
		this.propagateChange({
			"question_id": this.question.QuestionId,
			"question_type": this.question.Type,
			"response": this.internal_response
		});
	}

	public writeValue(answer: any) {
		this.internal_response = answer;
	}

	// returns null when valid else the validation object
	public validate(c: FormControl) {
		if (this.internal_response == null) {
			return {"error": "Please answer the question"};
		}else if (this.internal_response > this.question.Max || this.internal_response < this.question.Min){
			return {"error": "Please provide a valid answer (" + this.question.Min + "-" + this.question.Max + ")" };
		}else {
			let decimal_index = this.internal_response.toString().indexOf(".");
			let num_decimals = (decimal_index == -1) ? 0 : this.internal_response.toString().slice(decimal_index+1).length;
			if (num_decimals > this.question.Decimals){
				return {"error": "Too many decimal places"};
			}else return null;
		}
	}

	ngOnInit() {
		if (this.question.Decimals > 1) this.step = 1 / Math.pow(10, this.question.Decimals);
		this.width = ((Math.ceil(Math.log10(this.question.Max)) + this.question.Decimals) * 10) + 40;
	}

}
