import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, Observer, Subscription, of, empty } from 'rxjs';
import { SessionDataService } from 'app/shared/services/session-data.service';
import { PrescreenerService } from 'app/shared/services/prescreener.service';


@Injectable()
export class ProjectGdprResolver {

	constructor(private screener_service: PrescreenerService, private session_data: SessionDataService, private router: Router){}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		let query_params = route.queryParams;
		this.screener_service.PanelistUuid = query_params["id"];
		this.screener_service.RLnumber = query_params["rl"];
		this.screener_service.LanguageCode = query_params["lang"] || "en";
		if (this.screener_service.gdpr_accepted) return of(false);

		let screener_id = route.params["screener_id"];
		return Observable.create((observer: Observer<boolean>) => {
			let project_sub : Subscription = this.session_data.apiGet("/projects/gdpr?screener_id=" + screener_id).subscribe(
				data => {
					let values = [];
					for(var key in data.result) values.push(data.result[key]);

					if (values.some(pii_collected=>pii_collected))
						observer.next(true);
					else {
						this.screener_service.GdprAccepted = true;
						observer.next(false);
					}
				},
				error => {
					this.router.navigate(["/not-found"]);
					project_sub.unsubscribe();
					observer.error(error);
				},
				() => {
					project_sub.unsubscribe();
					observer.complete();
				}
			)
		});
	}
}
