import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrescreenerService, SessionDataService } from 'app/shared/services';
import { Prescreener } from 'app/shared/definitions';
import { Subscription } from 'rxjs';

@Component({
	selector: 'qm-test-suite',
	templateUrl: './test-suite.component.html',
	styleUrls: ['./test-suite.component.scss']
})
export class TestSuiteComponent implements OnInit, OnDestroy {

	private screener_uuid: string;
	private testing_password : string;

	public error_code: string;
	public error_reason: string;
	private error_subscription: Subscription;

	constructor(
		private session_data: SessionDataService,
		private router: Router,
		private route: ActivatedRoute,
		private screener_service: PrescreenerService
	) {}

	private handleError(error : any){
		this.error_code = error.result.code;
		this.error_reason = error.result.reason;
	}

	getTestIconStyle(testName : string){
		const test = this.screener_service.Prescreener.Tests.find(test => test.test_name === testName);
		if (!test) {
			return 'far fa-hourglass';
		}
		return test.passed ? 'fa fa-check-circle' : 'fa fa-times-circle'
	}


	resetRecord(){
		this.session_data.apiGet("/test/" + this.testing_password + "/screeners/" + this.screener_uuid + "/operations/reset" + this.screener_service.QueryString).subscribe(data => {
			if (this.screener_service.Prescreener) this.screener_service.Prescreener.reset();
			this.router.navigate(['/test', this.testing_password, this.screener_uuid], {"queryParams": this.route.snapshot.queryParams});
		});
	}

	setLanguageTo(language_code: string){
		this.screener_service.LanguageCode = language_code;
		let new_params = Object.assign({}, this.route.snapshot.queryParams);
		new_params["lang"] = language_code;
		this.router.navigate(['/test', this.testing_password, this.screener_uuid], {"queryParams": new_params});
	}

	ngOnInit() {
		this.testing_password = this.route.snapshot.params["password"];
		this.screener_service.setTestMode(true, "/test/" + this.testing_password);
		this.screener_uuid = this.route.snapshot.params["screener_id"];
		this.error_subscription = this.screener_service.broadcast_subject.subscribe(error => this.handleError(error));
	}

	ngOnDestroy() {
		if (this.error_subscription) this.error_subscription.unsubscribe();
	}

}
