export const environments = {
    localDev: 'Dev',
    staging: 'QA',
    prod: 'Prod'
};

const NON_DEV_ENVS = [
    environments.staging.toLowerCase(),
    environments.prod.toLowerCase()
];

let isLocalDev = null;
export const isLocalDevEnvironment = envName => {
    if (isLocalDev === null) {
        isLocalDev = !NON_DEV_ENVS.includes(envName.toLowerCase());
    }
    return isLocalDev;
};
