import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { GeoQuestion, Trigger, Reference } from 'app/shared/definitions';
import { PreviewBase } from 'app/shared/components/screener-questions/preview-base';
import { SessionDataService, PrescreenerService } from 'app/shared/services';
import { Subscription, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, NG_VALIDATORS, FormControl  } from '@angular/forms';


@Component({
  selector: 'qm-geo-preview',
  templateUrl: './geo-preview.component.html',
  styleUrls: ['./geo-preview.component.scss'],
  exportAs: 'preview',
  providers: [
  {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => GeoPreview),
	multi: true,
  },
  {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => GeoPreview),
	multi: true,
  }
  ]
})
export class GeoPreview extends PreviewBase implements OnInit, ControlValueAccessor, Validator{

	@Input() question: GeoQuestion;

	protected internal_response: Reference;
	private temp_geozone : Object;
	private lookup_value: string;
	private error_message: string;

	private quick_lookup_subsciption: Subscription;

	constructor(protected screener_service: PrescreenerService, private session_data: SessionDataService) {
		super();
	}

	ngOnInit(){
		this.requestLocation(true);
		// this.setUsingLocation({"latitude": 35.399889, "longitude": -77.12358});
	}

	geoErrorHandler(error, automatically_requested){
		if (automatically_requested) return;
		switch(error.code) {
			case error.PERMISSION_DENIED:
				this.error_message = "Geolocation has been blocked for this device";
			break;
			case error.POSITION_UNAVAILABLE:
				this.error_message = "Your location could not be determined at this time";
			break;
			case error.TIMEOUT:
				this.error_message = "Your location could not be determined at this time";
			break;
			case error.UNKNOWN_ERROR:
				this.error_message = "An unknown error has occurred"
			break;
		}
	}



	queryGeoData(search_term: string) {
		this.error_message = null;
		if (this.temp_geozone == undefined || this.temp_geozone["id"] == undefined){
			this.internal_response = undefined;
		}
		let sort_key: string = this.question.Scope.scope;
		let url = this.question.Scope.route + "?limit=10&fields=name&sort=name:asc";
		if (search_term.trim().length > 0) url+= "&search=" + search_term;
		return this.session_data.apiGet(url).pipe(
			map(data=>data.result)
		);
	}

/*************** Quick Entry ***********/

	setUsingLocation(coordinates: any){
		this.error_message = null;
		this.temp_geozone = undefined;
		let lat = coordinates.latitude; let lng = coordinates.longitude;
		let url = this.question.Scope.route + "/query_by_location?lat="+lat+"&lng="+lng;
		if (this.quick_lookup_subsciption) this.quick_lookup_subsciption.unsubscribe();
		this.quick_lookup_subsciption = this.session_data.apiGet(url).subscribe(data=>{
			if (data.result.length == 0){
				this.error_message = "Lookup failed.  Could not determine your " + this.question.Scope.scope;
			}else{
				this.temp_geozone = data.result[0];
				this.selectAnswer(data.result[0]);
			}
		}, error => {
			this.error_message = "Lookup failed.  Could not determine your " + this.question.Scope.scope;
		});
	}


	requestLocation(automatically_requested: boolean){
		if(navigator.geolocation){
			navigator.geolocation.getCurrentPosition(position => {
				this.setUsingLocation(position.coords)
			}, error => this.geoErrorHandler(error, automatically_requested));
		}else {
			this.error_message = "Unfortunately location service is disabled for your device";
		}
	}

	lookupFromSmallest(){
		this.error_message = null;
		this.temp_geozone = undefined;
		if (this.lookup_value == undefined) return;
		let url = this.question.Scope.route+"/query_by_smallest?smallest="+this.lookup_value;
		if (this.quick_lookup_subsciption) this.quick_lookup_subsciption.unsubscribe();
		this.quick_lookup_subsciption = this.session_data.apiGet(url).subscribe(data=>{
			if (data.result.length == 0){
				this.error_message = "Lookup failed.  Could not determine your " + this.question.Scope.scope;
			}
			this.temp_geozone = data.result[0];
			this.selectAnswer(data.result[0]);
		}, error => {
			this.error_message = "Lookup failed.  Could not determine your " + this.question.Scope.scope.toString();
		});
	}



//-----------------------------


	selectAnswer(answer: Reference){
		if (answer == undefined){
			this.temp_geozone = undefined;
			answer = undefined;
		}
		this.internal_response = answer;
		this.propagateChange({
			"question_id": this.question.QuestionId,
			"field": this.question.Scope.panelist_field,
			"question_type": this.question.Type,
			"response": this.internal_response
		});
	}

	// this is the initial value set to the component
	public writeValue(answer: any) {
		if (answer !== undefined && answer !== null)
		this.internal_response = answer["response"];
	}

	// returns null when valid else the validation object
	public validate(c: FormControl) {
		return (this.internal_response == undefined || this.internal_response.id == undefined)  ? {"error": "Please select your " + this.question.Scope.scope} : null;
	}
}
