import { QuestionBase } from './base.question';


interface Option {
    "id": any,
	"text": string,
	"groups": string[]
}


export class ChecklistQuestion extends QuestionBase {

	private min: number = 1;
	private max: number = 1;

	private options: Option[] = [];


	private none_option:  Option = undefined;
	private other_option: Option = undefined;


	get Min() : number { return this.min; }
	get Max() : number { return this.max; }
	set Min(min: number) { this.min = min; }
	set Max(max: number) { this.max = max; }


	get Options(): Option[] { return this.options; }
	set Options(opts: Option[]) { this.options = opts; }

	get NoneOption():  Option { return this.none_option; }
	set NoneOption(none: Option) { this.none_option = none; }

	get OtherOption():  Option { return this.other_option; }
	set OtherOption(other:  Option) { this.other_option = other; }

	constructor(){
		super();
		this.type = "checklist";
	}
}
