import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LDClient, LDUser, initialize as initializeLaunchDarkly } from "launchdarkly-js-client-sdk";
import { environment } from '../../../environments/environment';

@Injectable()
export class LaunchDarklyService {
    public client: LDClient;
    private user: LDUser;
    private initialized: boolean = false;

    private allFlagVals: object = null;

    private hCaptchaIsEnabled: boolean;
    private hCaptchaAlwaysOn: boolean;

    public get hCaptchaEnabled(): boolean {
        if (!this.initialized) {
            return null;
        }

        return (this.hCaptchaIsEnabled || this.hCaptchaAlwaysOn);
    }

    constructor(private route: ActivatedRoute) {
        let userKey = this.route.snapshot.params['screener_id'];
        
        if (!userKey) {
            userKey = (this.route.snapshot.children && this.route.snapshot.children.length) 
                ? this.route.snapshot.children[0].params['screener_id']
                : 
                `${route.snapshot.queryParams["rl"]}_${route.snapshot.queryParams["id"]}`;
        }

        this.user = {
            // NOTE: The below would be a more accurate assessment of "monthly active users" but to save money, we'll just use the prescreener for now...
            // key: `${route.snapshot.queryParams["rl"]}_${route.snapshot.queryParams["id"]}` 
            key: userKey
        };
        
        this.client = initializeLaunchDarkly(environment.launchDarklyClientSideId, this.user);

        // Don't hold up construction for this but let's kick off feature flag retrieval...
        this.isFeatureActivated(FeatureFlags.HCaptchaEnabled)
            .then(hcaptchaEnabled => {
                this.hCaptchaIsEnabled = hcaptchaEnabled;
                return this.isFeatureActivated(FeatureFlags.AlwaysEvaluateHCaptcha);
            })
            .then(alwaysOnHCaptcha => {
                this.hCaptchaAlwaysOn = alwaysOnHCaptcha;
            });
    }

    get(flag_name: string, defaultVal?: boolean) {
        if (!this.allFlagVals) {
            throw new Error('You may not call the "get" method until after the Feature Flag Service has been initialized!');
        }

        return this.allFlagVals.hasOwnProperty(flag_name) ? this.allFlagVals[flag_name] : defaultVal;
    }

    async isFeatureActivated(flag_name: string, defaultVal: boolean = false): Promise<boolean> {
        if (this.allFlagVals != null && Object.keys(this.allFlagVals).includes(flag_name)) {
            return this.allFlagVals[flag_name];
        }

        if (!this.initialized) {
            await this.client.waitForInitialization();
            this.initialized = true;
        }

        let result: boolean;

        try {
            result = this.client.variation(flag_name, defaultVal);
        }
        catch (e) {
            console.error(`Failed to retrieve feature flag "${flag_name}" from LaunchDarkly!`, e);
            result = defaultVal;
        }

        return result;
    }

    async loadAll(): Promise<object> {
        if (!this.initialized) {
            await this.client.waitForInitialization();
            this.initialized = true;
        }

        try {
            this.allFlagVals = this.client.allFlags();
        }
        catch (e) {
            console.error('Attempt to load all feature flags failed!', e);
        }
        finally {
            return this.allFlagVals;
        }
    }
}

export const FeatureFlags = {
    HCaptchaEnabled: 'enable-hcaptcha',
    AlwaysEvaluateHCaptcha: 'always-evaluate-hcaptcha',
};

