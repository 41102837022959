import { QuestionBase, Answer } from 'app/shared/definitions';

export abstract class PreviewBase {

	protected abstract question: QuestionBase;
	get QuestionId() : string { return this.question.QuestionId; }

	constructor() {}

	protected abstract internal_response: any;

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) { this.propagateChange = fn; }
	// the method set in registerOnChange, it is just a placeholder for a method that takes one parameter,
	// we use it to emit changes back to the form
	protected propagateChange = (_: any) => { };
	// not used, used for touch input
	public registerOnTouched() { }

}
