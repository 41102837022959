import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class UrlParamNormalizer extends DefaultUrlSerializer {
	parse(url: string): UrlTree {
		// Optional Step: Do some stuff with the url if needed.
		let parsed = super.parse(url);
		let keys = Object.keys(parsed.queryParams);
		let params = {};
		for (let key of keys)
			params[key.toLowerCase()] = parsed.queryParams[key];
		parsed.queryParams = params;
		return parsed;
	}
}
