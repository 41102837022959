import { Component, Input, forwardRef } from '@angular/core';
import { PatternQuestion, Trigger } from 'app/shared/definitions';
import { PreviewBase } from 'app/shared/components/screener-questions/preview-base';
import { PrescreenerService } from 'app/shared/services/prescreener.service';
import { Observable } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, NG_VALIDATORS, FormControl  } from '@angular/forms';



@Component({
  selector: 'qm-pattern-preview',
  templateUrl: './pattern-preview.component.html',
  styleUrls: ['./pattern-preview.component.scss'],
  exportAs: 'preview',
  providers: [
  {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => PatternPreview),
	multi: true,
  },
  {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => PatternPreview),
	multi: true,
  }
  ]
})
export class PatternPreview extends PreviewBase {

	@Input() question: PatternQuestion;
	protected internal_response: string;

	constructor(protected screener_service: PrescreenerService) {
		super();
	}


	protected updateInput(answer: string){
		this.internal_response = answer;
		this.propagateChange({
			"question_id": this.question.QuestionId,
			"question_type": this.question.Type,
			"response": this.internal_response
		});
	}

	// this is the initial value set to the component
	public writeValue(answer: any) {
		if (answer !== undefined && answer !== null)
		this.internal_response = answer["response"];
	}

   // returns null when valid else the validation object
   public validate(c: FormControl) {
	   if (this.internal_response == null || this.internal_response.trim().length == 0){
		   return {"error": "Please answer the question"};
	   }else if (this.internal_response.trim().length < this.question.MinLength){
		   return {"error": "Answer must be at least " + this.question.MinLength + " characters long"};
	   }else if (!this.question.TerminateInvalid && !this.question.matches(this.internal_response.trim(), "i")){
		   return {"error": "Invalid format. Please try again."};
	   }else return null;
   }
}
