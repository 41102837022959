import { Component, Input, forwardRef } from '@angular/core';
import { ChecklistQuestion, Trigger } from 'app/shared/definitions';
import { PreviewBase } from 'app/shared/components/screener-questions/preview-base';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, NG_VALIDATORS, FormControl  } from '@angular/forms';


@Component({
  selector: 'qm-checklist-preview',
  templateUrl: './checklist-preview.component.html',
  styleUrls: ['./checklist-preview.component.scss'],
  exportAs: 'preview',
  providers: [
  {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ChecklistPreview),
	multi: true,
  },
  {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => ChecklistPreview),
	multi: true,
  }
  ]
})
export class ChecklistPreview extends PreviewBase implements ControlValueAccessor, Validator {

	@Input() question: ChecklistQuestion;
	protected internal_response: {"id": string, "text": string}[] = [];
	private use_other:boolean = false;
	private other_response:string = null;
	public num_selected: number = 0;

	constructor() {
		super();
	}

	getIndexOf(answer_id: number | string){
		let i:number = -1;
		for (let option of this.internal_response){
			i++;
			if (option['id'] === answer_id) return i;
		}
		return -1;
	}

	otherUpdated(other){
		this.other_response = other;
		let olduse_other = this.use_other;
		this.use_other = other.trim().length > 0;
		if (olduse_other !== this.use_other) this.onToggleOption(this.question.OtherOption);
		this.storeAnswer();
	}


	onOtherToggled(){
		if (this.use_other){
			this.onToggleOption(this.question.OtherOption);
			this.other_response = "";
			this.use_other = false;
		}
		this.num_selected = this.internal_response.length;
		this.storeAnswer();
	}

	onToggleOption(option : {"id": string, "text": string, "groups": string[]}){
		// not in our array of selected responsex.  add this option.
		let location:number = this.getIndexOf(option.id);
		if (location == -1){
			// we had selected none of the above.   remove that in order to replace it with one of the above
			if (this.question.NoneOption !== undefined && this.getIndexOf(this.question.NoneOption.id) > -1){
				this.internal_response = [];
			}
			this.internal_response.push({"id": option.id, "text": option.text});
		}
		else this.internal_response.splice(location, 1);
		this.num_selected = this.internal_response.length;
		this.storeAnswer();
	}


	onNoneSelected(){
		if (this.getIndexOf(this.question.NoneOption.id) == -1){
			this.internal_response = [{"id": this.question.NoneOption.id, "text": this.question.NoneOption.text}];
		}else{
			this.internal_response = [];
		}
		this.num_selected = 1;
		this.storeAnswer();
	}

	protected storeAnswer(){
		let res = {"answers": this.internal_response};
		if (this.other_response !== null && this.other_response !== undefined) res["other"] = this.other_response;
		this.propagateChange({
			"question_id": this.question.QuestionId,
			"question_type": this.question.Type,
			"response": res
		});
	}


	public writeValue(answer: any) {
		if (answer == undefined || answer == null || answer.response == undefined) return;
		this.internal_response = answer.response["answers"];
		this.other_response = answer.response["other"];
	}

	// returns null when valid else the validation object
	public validate(c: FormControl) {
		if (this.internal_response.length >= this.question.Min && this.internal_response.length <= this.question.Max){
			return null;
		}else{ // otherwise, cancel all functions caused by this question.
			return {"error": "Please select " + this.question.Min + " to  " + this.question.Max + " answers"};
		}
	}


}
